import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Web3 from "web3";
import { toast } from "react-toastify";

const hostURL =
  "https://nftbazar-backend-main-104732163593.europe-west9.run.app/";
// const hostURL = "http://localhost:8000/"
const artistURL = `${hostURL}artist/file/`;
const previewURL = `${hostURL}nft/prevfile/`;
const originalURL = `${hostURL}nft/file/`;
const localHost ="http://localhost:8000/"

const formatName = (name) => {
  return name.replace(/ /g, "_").toLowerCase();
};

const parseName = (name) => {
  return name ? name.replace(/_/g, " ").toLowerCase() : "";
};

const useToGetUserData = () => {
  const [userData, setUserData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const apiURL = `${hostURL}user`;
      try {
        const response = await axios.get(apiURL, {
          withCredentials: true,
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return userData;
};

const useToGetString = (element, link) => {
  const [fetchStroke, setFetchStroke] = useState(null);

  useEffect(() => {
    if (!element || !link) return;

    const getStrokes = async () => {
      try {
        const fetchPromises = Array.isArray(element)
          ? element.map((id) =>
              axios
                .get(`${hostURL}${link}/${id}`)
                .then((response) => response.data)
            )
          : [
              axios
                .get(`${hostURL}${link}/${element}`)
                .then((response) => response.data),
            ];

        const results = await Promise.all(fetchPromises);
        setFetchStroke(Array.isArray(element) ? results : results[0]);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    getStrokes();
  }, [element, link]);

  return fetchStroke;
};

const useToGetItemData = (id, link, count = 10, startId = 1) => {
  const [artistItem, setArtistItem] = useState(null);

  useEffect(() => {
    if (!id || !link) return;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${hostURL}${link}/${id}?count=${count}&start_id=${startId}`
        );
        setArtistItem(response.data);
      } catch (error) {
        // console.error("Error fetching item data:", error);
      }
    };

    fetchData();
  }, [id, link, count, startId]);

  return { artistItem };
};

const useToGetData = (
  route,
  count = 10,
  offset = 0,
  delay = 0,
  filters = {}
) => {
  const [blockItems, setBlockItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!route) return;

    const fetchData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, delay));

      let apiURL = `${hostURL}${route}?count=${count}&offset=${offset}`;

      const normalizedFilters = Object.fromEntries(
        Object.entries(filters).map(([key, value]) => [
          key.trim().toLowerCase(),
          typeof value === "string" ? value.trim().toLowerCase() : value,
        ])
      );

      if (normalizedFilters.price && normalizedFilters.price !== "all") {
        let priceValue = normalizedFilters.price;

        if (priceValue === "under 1k usd") {
          priceValue = 1000;
        } else if (priceValue === "under 5k usd") {
          priceValue = 5000;
        } else if (priceValue === "under 10k usd") {
          priceValue = 10000;
        }

        apiURL += `&price=${encodeURIComponent(priceValue)}`;
      }

      Object.entries(normalizedFilters).forEach(([key, value]) => {
        if (value && value !== "all" && value !== "" && key !== "price") {
          apiURL += `&${key}=${encodeURIComponent(value)}`;
        }
      });

      try {
        const response = await axios.get(apiURL);
        console.log("Data Response:", response);

        setBlockItems(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [route, count, offset, delay, filters]);

  return { blockItems, loading };
};

const useToFindItem = (type, itemID) => {
  const [itemData, setItemData] = useState();
  useEffect(() => {
    if (!itemID) return;
    const fetchData = async () => {
      const apiURL = `${hostURL}${type}/${itemID}`;
      try {
        const response = await axios.get(apiURL);
        setItemData(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
  }, [type, itemID]);
  return itemData;
};

const useToGetCollection = (type, owner_id) => {
  const [dataArray, setDataArray] = useState();
  useEffect(() => {
    if (!owner_id) return;
    const fetchData = async () => {
      const apiURL = `${hostURL}${type}?artist_id=${owner_id}`;
      try {
        const response = await axios.get(apiURL);
        setDataArray(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
  }, [owner_id]);

  return dataArray;
};

export const logoutWallet = async () => {
  try {
    localStorage.removeItem("loginMethod");
    localStorage.removeItem("ethAddress");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("token");
    console.log("Logout successful!");
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

const useToList = (route, type, count = 10, startId = 0) => {
  const { blockItems } = useToGetData(route, count, startId);
  const [fetchList, setFetchList] = useState([]);

  useEffect(() => {
    if (!blockItems || !type) return;

    const typeList = blockItems.flatMap((item) =>
      Array.isArray(item[type]) ? item[type] : [item[type]]
    );

    const uniqueList = Array.from(
      new Set(typeList.map((item) => item.trim().toLowerCase()))
    );

    setFetchList(uniqueList);
  }, [type, blockItems]);

  return fetchList;
};

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => clearTimeout(timer);
  }, [location]);

  return null;
};

export const registerUser = async (userData) => {
  const url = `${hostURL}user/register`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();

    if (!response.ok) {
      let errorMessage = "";

      if (data.detail && typeof data.detail === "string") {
        errorMessage = data.detail;
      } else if (
        data.detail &&
        Array.isArray(data.detail) &&
        data.detail.length > 0
      ) {
        const errorDetail = data.detail[0];
        errorMessage = errorDetail.msg || "Registration failed";
      } else {
        errorMessage = "Registration failed";
      }

      throw new Error(errorMessage);
    }

    return data;
  } catch (error) {
    console.error("Error during registration:", error.message);
    throw error;
  }
};

export const postCheckCode = async (code, userEmail) => {
  const url = new URL(`${hostURL}user/check_code`);
  url.searchParams.append("code", code);
  url.searchParams.append("user_email", userEmail);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, errorData };
    }
  } catch (error) {
    console.error("Error during check code:", error);
    return { success: false, error: "Failed to connect to the server." };
  }
};

export const postLogin = async (email, password) => {
  try {
    const response = await fetch(`${hostURL}user/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      let errorMessage = "";

      if (errorData.detail && typeof errorData.detail === "string") {
        errorMessage = errorData.detail;
      } else if (
        errorData.detail &&
        Array.isArray(errorData.detail) &&
        errorData.detail.length > 0
      ) {
        const errorDetail = errorData.detail[0];
        errorMessage = errorDetail.msg || "Invalid email or password.";
      } else {
        errorMessage = errorData.message || "Something went wrong.";
      }

      return { success: false, errorMessage };
    }

    localStorage.setItem("loginMethod", "Email");
    localStorage.setItem("userEmail", email);

    return { success: true };
  } catch (error) {
    console.error("Error during login:", error);
    return { success: false, errorMessage: "Failed to connect to the server." };
  }
};

export const verifyLogin = async () => {
  try {
    const verifyResponse = await fetch(`${hostURL}user`, {
      method: "GET",
      credentials: "include",
    });

    if (verifyResponse.ok) {
      return { success: true };
    } else {
      return {
        success: false,
        errorMessage: "Unauthorized access. Please log in again.",
      };
    }
  } catch (error) {
    console.error("Error during login verification:", error);
    return { success: false, errorMessage: "Failed to connect to the server." };
  }
};

export const postResendCode = async (email) => {
  try {
    const url = new URL(`${hostURL}user/resend_code`);
    url.searchParams.append("email", email);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      let errorMessage = "Failed to resend code.";

      if (errorData.detail && Array.isArray(errorData.detail)) {
        errorMessage = errorData.detail
          .map((error, index) => `${index + 1}: ${error.msg}`)
          .join("\n");
      } else if (errorData.detail && typeof errorData.detail === "string") {
        errorMessage = errorData.detail;
      }

      return { success: false, errorMessage };
    }
  } catch (error) {
    console.error("Error during resend code:", error);
    return { success: false, errorMessage: "Failed to connect to the server." };
  }
};

const authWeb3 = async (address, message, signature) => {
  try {
    const response = await axios.post(
      `${hostURL}web3_auth`,
      {
        address: address,
        message: message,
        signature: signature,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response:", response.data);
  } catch (error) {
    if (error.response) {
      console.error("Error:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
};

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return JSON.parse(localStorage.getItem("isAuthenticated")) || false;
  });
  const [userEmail, setUserEmail] = useState(
    () => localStorage.getItem("userEmail") || null
  );
  const [ethAddress, setEthAddress] = useState(
    () => localStorage.getItem("ethAddress") || null
  );

  const authenticateWeb3 = async (account) => {
    const message = "Please sign this message to authenticate.";
    const web3 = new Web3(window.ethereum);

    try {
      const signature = await web3.eth.personal.sign(message, account, "");
      const authResponse = await axios.post(
        `${hostURL}web3_auth`,
        {
          address: account,
          message: message,
          signature: signature,
        },
        { withCredentials: true }
      );

      if (authResponse.status === 204) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error("Web3 authentication failed:", error);
      return { success: false };
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${hostURL}user`, {
          withCredentials: true,
        });
        if (response.data) {
          const { eth_account_address, username, email } = response.data;
          setIsAuthenticated(true);
          setUserEmail(email);
          setEthAddress(eth_account_address);
          localStorage.setItem("isAuthenticated", JSON.stringify(true));
          localStorage.setItem("userEmail", email);
          if (eth_account_address) {
            localStorage.setItem("ethAddress", eth_account_address);
          }
        } else {
          setIsAuthenticated(false);
          localStorage.clear();
        }
      } catch (error) {
        setIsAuthenticated(false);
        localStorage.clear();
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
    localStorage.setItem("userEmail", userEmail);
    localStorage.setItem("ethAddress", ethAddress);
  }, [isAuthenticated, userEmail, ethAddress]);

  const updateEthAddress = (address) => {
    setEthAddress(address);
    localStorage.setItem("ethAddress", address);
  };

  const loginWithEmail = (email) => {
    setIsAuthenticated(true);
    setUserEmail(email);
    localStorage.setItem("isAuthenticated", JSON.stringify(true));
    localStorage.setItem("userEmail", email);
  };

  return {
    isAuthenticated,
    userEmail,
    ethAddress,
    loginWithEmail,
    authenticateWeb3,
    updateEthAddress,
  };
};

export const postLogout = async () => {
  try {
    const response = await fetch(`${hostURL}user/logout`, {
      method: "POST",
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      let errorMessage = errorData.message || "Failed to log out.";
      return { success: false, errorMessage };
    }

    return { success: true };
  } catch (error) {
    console.error("Error during logout:", error);
    return { success: false, errorMessage: "Failed to connect to the server." };
  }
};

export const sendPasswordResetRequest = async (email) => {
  const apiURL = `${hostURL}user/password_reset_request`;
  try {
    const response = await axios.post(apiURL, { email });
    console.log("Password reset request sent:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending password reset request:", error);
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  const apiURL = `${hostURL}user/password_reset`;
  try {
    const response = await axios.post(apiURL, {
      token,
      new_password: newPassword,
    });
    console.log("Password reset successful:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

const toTitleCase = (str) => {
  if (!str) return null;
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export {
  formatName,
  useAuth,
  useToGetItemData,
  useToGetData,
  useToFindItem,
  useToList,
  ScrollToTop,
  useToGetString,
  useToGetUserData,
  useToGetCollection,
  toTitleCase,
  authWeb3,
  hostURL,
  artistURL,
  previewURL,
  originalURL,
  localHost,
};
