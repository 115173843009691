import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Fashion.scss";
import hoodie_image from "../../assets/Fashion/customized/hoodie_she.png";
import qr_hoodie from "../../assets/Fashion/customized/qr_hoodie.png";

import {
  FashionFeaturesData,
  FashionGalleryData,
  FashionProductsData,
} from "../../data";

import hoodie_item from "../../assets/Fashion/items/hoodie.jpg";
import bag_item from "../../assets/Fashion/items/bag.png";
import tshirt_item from "../../assets/Fashion/items/tshirt.jpg";

import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { Link } from "react-router-dom";

import { hostURL } from "../../data_requests";

const itemsToSail = [tshirt_item, hoodie_item, bag_item];

const Fashion = () => {
  const [artworks, setArtworks] = useState([]);
  const [artists, setArtists] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const handleDetailsClick = () => setIsDetailsVisible(true);
  const handleBackClick = () => setIsDetailsVisible(false);
  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent;
      const isMobileDevice =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      setIsMobile(isMobileDevice);
    };

    checkIfMobile();

    const fetchArtworks = async () => {
      try {
        const response = await axios.get(`${hostURL}fashion`);
        setArtworks(response.data);

        response.data.forEach(async (artwork) => {
          try {
            const artistResponse = await axios.get(
              `${hostURL}artist/${artwork.artist_id}`
            );
            setArtists((prevArtists) => ({
              ...prevArtists,
              [artwork.artist_id]: artistResponse.data,
            }));
          } catch (error) {
            console.error(`Artist arror ID ${artwork.artist_id}:`, error);
          }
        });
      } catch (error) {
        console.error("Artwork uploading issue:", error);
      }
    };

    fetchArtworks();
  }, []);

  return (
    <div className="app">
      <header className="header">
        <div className="header-overlay">
          <div className="header_content">
            <h1 className="start_h1">Get Started with Funooni Fashion Tool</h1>
            {!isMobile ? (
              <Link to="/fashion_tool" className="start-button">
                <p className="start-inner">START DEMO</p>
              </Link>
            ) : (
              <p className="start-button disabled-button">
                Demo is not available on mobile devices
              </p>
            )}
          </div>
        </div>
      </header>

      <section className="products">
        {FashionProductsData.map((product, index) => (
          <div key={product.id} className="product-item">
            <div
              className="prod-items"
              style={{ backgroundImage: `url(${itemsToSail[index]})` }}
              alt={product.title}
            />
            <div className="overlay_y">
              <div className="overlay_y_title overlay_y_title2">
                <div className="title_content">
                  <h3>Customize Your</h3>
                  <h3 className="h3">{product.title}</h3>
                </div>

                {index === 1 || index === 2 ? (
                  <div>Will be available soon</div>
                ) : !isMobile ? (
                  <Link to="/fashion_tool" className="create-btn">
                    Create Demo
                  </Link>
                ) : (
                  <p className="create-btn disabled-button">
                    Demo is not available on mobile devices
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>

      <SectionTitle title="About Funooni Fashion" />
      <section className="new_about">
        <div className="features">
          {FashionFeaturesData.map((feature) => (
            <div key={feature.id} className="feature">
              <img src={feature.icon} alt={feature.title} />
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>
      <div className="fashion-container">
        <SectionTitle title="Customized NFT Fashion" />

        <div className="fashion-card-container">
          {!isDetailsVisible && (
            <div className="fashion-card-right">
              <div
                className="hoodie-image"
                style={{ backgroundImage: `url(${hoodie_image})` }}
              ></div>
              <div className="fashion-actions">
                <Link to="https://buy.stripe.com/aEU4id0LS958aGI6rr" className="fashion-btn white">Purchase</Link>
                <button
                  className="fashion-btn black"
                  onClick={handleDetailsClick}
                >
                  Details
                </button>
              </div>
            </div>
          )}

          {isDetailsVisible && (
            <div className={`fashion-card-left show`}>
              <div className="left">
                <div className="container-qr-code">
                  <div
                    className="qr-code"
                    style={{ backgroundImage: `url(${qr_hoodie})` }}
                  ></div>
                </div>
                <div className="fashion-details">
                  <div className="fashion-item">
                    <span>Artwork name:</span>
                    <span>water melon maa'lish</span>
                  </div>
                  <div className="fashion-item">
                    <span>Artist:</span>
                    <span>Reham Shaheen</span>
                  </div>
                  <div className="fashion-item">
                    <span>Collection:</span>
                    <span>Water Melon Maa'lish</span>
                  </div>
                  <div className="fashion-actions-2">
                    <button className="fashion-btn" onClick={handleBackClick}>
                      Back
                    </button>
                    <Link to="https://funooni.com/nft/water_melon_maa'lish-13" className="question-mark">?</Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <section className="art-gallery">
        {FashionGalleryData.map((art) => (
          <div
            key={art.id}
            style={{ backgroundImage: `url(${art.image})` }}
            className="gallery-item"
          ></div>
        ))}
      </section>

      <SectionTitle title="Available artworks for Printing" />
      <div className="gallery-container">
        <div className="gallery-grid">
          {artworks.map((art) => (
            <div
              key={art.id}
              className="artwork-card"
              style={{ backgroundImage: `url(${art.prev_img_url})` }}
            >
              <div className="overlay_y">
                <div className="overlay_y_title">
                  <h3>{art.name}</h3>
                </div>
                <div className="card-footer">
                  <div className="card-footer-content">
                    <div className="card_content">
                      <strong>{art.name}</strong>
                      <p>{"Salah Shaheen"}</p>
                    </div>
                    {!isMobile ? (
                      <Link className="create-btn" to="/fashion_tool">
                        Create
                      </Link>
                    ) : (
                      <p className="create-btn disabled-button">
                        Demo is not available on mobile devices
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Fashion;
