import React, { useMemo } from "react";
import { Decal, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import decal_01 from "../../assets/logo.png"

function Model({
  supportColor,
  BaseColor,
  decalUVOffset,
  decalScale,
  decalRotation,
  decalImage,
}) {
  const { nodes, materials } = useGLTF("/models/T-shirt.gltf");
  const decal_03 = `${decalImage}`;
  console.log(decal_03)
  const decal_02 = "https://i.pinimg.com/736x/ef/a6/db/efa6db78a524b2575076039e7ed7dd83.jpg";

  
  const decal_texture = useMemo(() => {
    if (decalImage) {
      return new THREE.TextureLoader().load(
        decalImage,
        undefined,
        undefined,
        (error) => {
          console.error("Failed to load texture:", error);
        }
      );
    }
    return null;
  }, [decalImage]);

  const updatedMaterials = useMemo(() => {
    if (materials) {
      Object.keys(materials).forEach((materialName) => {
        if (materials[materialName].name.toLowerCase().includes("fabric 3")) {
          materials[materialName].color.set(supportColor);
        }
        if (materials[materialName].name.toLowerCase().includes("fabric 1")) {
          materials[materialName].color.set(BaseColor);
        }
      });
    }
    return materials;
  }, [supportColor, BaseColor, materials]);

  if (!nodes || !updatedMaterials) {
    console.error("Required assets are missing.");
    return null;
  }

  return (
    <group>
      {nodes.T_shirt_fixed.children.map((child, index) => {
        if (child.geometry) {
          return (
            <mesh
              key={index}
              castShadow
              geometry={child.geometry}
              material={updatedMaterials[child.material.name]}
              dispose={null}
            >
              {decal_texture && (
                <Decal
                  position={[decalUVOffset.u, decalUVOffset.v, 0.09]}
                  rotation={[0, 0, decalRotation]}
                  scale={decalScale}
                  map={decal_texture}
                  depthTest={true}
                  depthWrite={false}
                  polygonOffset
                  polygonOffsetFactor={-1}
                />
              )}
            </mesh>
          );
        }
        return null;
      })}
    </group>
  );
}

export default React.memo(Model);